<template>
  <v-card class="modal-create">
    <v-form ref="form" v-model="valid">
      <v-card-title class="d-flex justify-center align-center mb-11">
        <span class="text-h5 mr-2 text-center">
          Создание интеграции
        </span>
        <img width="31" :src="require(`@/assets/img/integrations/${selectedTypeInt.value}.svg`)" alt=""/>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')" />
        </div>
        <v-col
            cols="12"
        >
          <v-text-field
              class="evi-text-field"
              color="#44D370"
              v-model="token"
              :rules="tgRules"
          >
            <template v-slot:label>
              API-токен бота
              <span class="important">*</span>
            </template>
          </v-text-field>
        </v-col>
      </v-card-text>
      <v-card-actions class="modal-create__actions">
        <v-btn class="evi-button-green evi-button-green--fill" @click="create">
          Создать интеграцию
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "CreateIntegrationTg",
  props: {
    selectedTypeInt: {
      type: Object,
    }
  },
  data: () => ({
    valid: true,
    tgRules: [(v) => !!v || 'Укажите API-токен бота'],
    token: '',
  }),
  methods: {
    ...mapActions({
      createIntegrationTg: 'integrations/createIntegrationTg',
    }),
    validate () {
      return !!this.$refs.form.validate();
    },
    create () {
      if (this.validate()) {
        let payload = {
          token: this.token,
        };

        this.createIntegrationTg(payload).then(() => this.closeDialog());
      }
    },
    closeDialog () {
      this.$emit('close');
      this.token = '';
      this.$refs.form.resetValidation();
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
