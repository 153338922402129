<template>
  <v-card class="modal-create">
    <v-form ref="form" v-model="valid">
      <v-card-title class="d-flex justify-center">
        <span class="text-h5 text-center mb-11">Настройка интеграции</span>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')" />
        </div>
        <v-col
            cols="12"
        >
          <v-text-field
              class="evi-text-field"
              color="#44D370"
              v-model="selectedInt.name"
              placeholder="Название"
              disabled
          >
          </v-text-field>
        </v-col>
        <v-col
            cols="12"
        >
          <v-autocomplete
              class="evi-autocomplete"
              color="#44D370"
              v-model="selectedUsers"
              :items="localUsers"
              item-text="name"
              item-value="id"
              placeholder="Выберите ответственных сотрудников"
              :rules="userRules"
              multiple
              :loading="userLoading"
              @focus="loadUsers"
              :search-input.sync="userSearch"
              @input.native="searchUser(userSearch)"
              cache-items
              clearable
              item-color="green"
          >
            <template v-slot:label>
              Выберите ответственных сотрудников
              <span class="important">*</span>
            </template>
            <template v-slot:append-item>
              <div v-intersect="userIntersect" />
            </template>
            <template v-slot:item="{item}">
              <v-avatar
                  color="#FFFFFF"
                  size="30"
                  class="user-page__avatar mr-3"
              >
                <img
                    :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                    alt=""
                >
              </v-avatar>
              <div class="v-list-item__title">{{item.name}}</div>
            </template>
            <template v-slot:append>
                <span class="evi-select__arrow">
                    <v-icon>mdi-chevron-down</v-icon>
                </span>
            </template>
          </v-autocomplete>
        </v-col>
      </v-card-text>
      <v-card-actions class="modal-create__actions">
        <v-btn class="evi-button-green evi-button-green--fill" @click="save">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import {mapActions} from "vuex";
  import {UserAutocompleteRequestsMixin} from "@/mixins/autocomplete/UserAutocompleteRequestsMixin";

export default {
  name: "EditIntegration",
  mixins: [UserAutocompleteRequestsMixin],
  props: {
    selectedInt: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    valid: true,
    selectedUsers: [],
    userRules: [(v) => !!v || 'Выберите сотрудников'],
    intUsers: [],
  }),
  computed: {
    localUsers () {
      let array = [...this.intUsers];
      this.users.forEach(i => {
        if (!this.intUsers.some(e => e.id === i.id)) {
          array.push(i);
        }
      });
      return array;
    },
  },
  methods: {
    ...mapActions({
      getIntegration: 'integrations/getIntegration',
      editIntegrationResponsibleUsers: 'integrations/editIntegrationResponsibleUsers',
    }),
    save () {
      this.editIntegrationResponsibleUsers(
          {
            id: this.selectedInt.id,
            selectedUsers: this.selectedUsers,
          }
      ).then((res) => {
        if (res) {
          this.$emit('update');
          this.closeDialog();
        }
      });
    },
    closeDialog () {
      this.$emit('close');
      this.$refs.form.resetValidation();
    },
    initIntegration(val) {
      this.selectedUsers = [];
      this.getIntegration(val).then((res) => {
        res.users.forEach(i => this.selectedUsers.push(i.id));
        this.intUsers = res.users;
      });
    }
  },
  mounted () {
    this.initIntegration(this.selectedInt.id);
  },
  watch: {
    selectedInt (val) {
      this.initIntegration(val.id);
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
