<template>
  <v-card class="modal-create forms-create">
    <v-form ref="form">
      <v-card-title class="d-flex justify-center align-center mb-11">
        <span class="text-h5 mr-2 text-center">
          Новая форма
        </span>
        <img width="31" :src="require(`@/assets/img/integrations/${selectedTypeInt.value}.svg`)" alt=""/>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')" />
        </div>
        <v-col
            cols="12"
        >
          <v-text-field
              class="evi-text-field form-name mb-2"
              color="#44D370"
              v-model="formName"
          >
          </v-text-field>
          <v-text-field
              class="evi-text-field form-name"
              color="#44D370"
              v-model="email"
              label="Дублировать на почту"
              :rules="emailRules"
          >
          </v-text-field>
        </v-col>
        <v-col
            cols="12"
        >
          <v-text-field
              v-for="(i, index) in fields"
              :key="index"
              class="evi-text-field"
              color="#44D370"
              v-model="i.placeholder"
          >
            <template v-slot:label>
              Название поля
            </template>
            <template v-slot:append>
              <v-icon v-if="showTrash(i.name)" @click="removeField(index)" class="pointer  " color="#A7AFB8">mdi-trash-can-outline</v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col
            cols="12"
        >
          <div class="add-field pointer mb-2" @click="newField">
            <v-icon>mdi-plus</v-icon>
            Добавить поле
          </div>
        </v-col>
        <v-col
            cols="12"
        >
          <v-textarea
              class="evi-textarea"
              color="#44D370"
              v-model="message"
              no-resize
          >
            <template v-slot:label>
              Сообщение после заполнения формы
            </template>
          </v-textarea>
        </v-col>
      </v-card-text>
      <v-card-actions class="modal-create__actions">
        <v-btn class="evi-button-green evi-button-green--fill" @click="create">
          Создать форму
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {mapActions} from "vuex";
import {translit} from "../../tools/Helpers";

export default {
  name: "CreateIntegrationForms",
  props: {
    selectedTypeInt: {
      type: Object,
    }
  },
  data: () => ({
    formName: 'Название формы',
    email: '',
    fields: [
      {
        placeholder: 'Имя',
        label: 'Имя',
        name: 'name',
        type: 'text',
      },
      {
        placeholder: 'Почта',
        label: 'Почта',
        name: 'email',
        type: 'text',
      },
      {
        placeholder: 'Телефон',
        label: 'Телефон',
        name: 'phone',
        type: 'text',
      },
    ],
    message: 'Данные отправлены. Скоро выйдем на связь!'
  }),
  computed: {
    emailRules() {
      return [
        (v) => !!v || 'Введите почту',
        (v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Неверный формат эл. почты',
      ];
    },
  },
  methods: {
    ...mapActions({
      createIntegrationForms: 'integrations/createIntegrationForms',
    }),
    create () {
      this.fields.forEach(i => {
        if (i.name === 'new_field') {
          i.name = translit(i.placeholder)
        }
        i.label = i.placeholder;
      });

      let payload = {
        name: this.formName,
        message: this.message,
        email: this.email || null,
        fields: this.fields,
      };

      this.createIntegrationForms(payload).then(() => {
        this.$emit('update');
        this.closeDialog();
      });
    },
    newField () {
      this.fields.push({
        placeholder: 'Новое поле',
        name: 'new_field',
        type: 'text',
      })
    },
    closeDialog () {
      this.$emit('close');
      this.name = 'Название формы';
      this.message = 'Данные отправлены. Скоро выйдем на связь!';
      this.fields = [
        {
          placeholder: 'Имя',
          name: 'name',
          type: 'text',
        },
        {
          placeholder: 'Почта',
          name: 'email',
          type: 'text',
        },
        {
          placeholder: 'Телефон',
          name: 'phone',
          type: 'text',
        },
      ];
    },
    showTrash(v) {
      return v !== 'name' && v !== 'email' && v !== 'phone';
    },
    removeField(i) {
      this.fields.splice(i, 1);
    }
  }
}
</script>

<style lang="scss">
.forms-create {
  .modal-create__form {
    background: linear-gradient(149.26deg, #F2F2F2 27.83%, #EBF5F9 81.36%);
    border-radius: 10px;
    width: 85%;
    margin: auto;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .form-name {
    .v-input__control input {
      font-weight: 700 !important;
    }
  }
}
</style>
