<template>
  <v-card class="modal-create">
    <v-form ref="form" v-model="valid">
      <v-card-title class="d-flex justify-center align-center mb-11">
        <span class="text-h5 mr-2 text-center">
          Создание интеграции
        </span>
        <img width="31" :src="require(`@/assets/img/integrations/${selectedTypeInt.value}.svg`)" alt=""/>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')" />
        </div>
        <v-col
            cols="12"
        >
          <v-autocomplete
              class="evi-autocomplete"
              color="#44D370"
              v-model="group"
              :items="groups"
              item-text="name"
              item-value="id"
              :placeholder="intGroupsPlaceholder"
              :rules="groupRules"
              return-object
              clearable
              item-color="green"
          >
            <template v-slot:label>
              <span class="mr-1" v-if="selectedTypeInt.value === 'vk'">Группы</span>
              <span class="mr-1" v-else>Профиль</span>
              <span class="important">*</span>
            </template>
            <template v-slot:append>
                <span class="evi-select__arrow">
                    <v-icon>mdi-chevron-down</v-icon>
                </span>
            </template>
          </v-autocomplete>
        </v-col>
      </v-card-text>
      <v-card-actions class="modal-create__actions">
        <v-btn class="evi-button-green evi-button-green--fill" @click="create">
          Создать интеграцию
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {mapActions} from "vuex";
import {eventBus} from '../../main';

export default {
  name: "CreateIntegration",
  props: {
    groups: {
      type: Array,
      required: true,
    },
    sessionId: {
      type: String,
      required: true,
    },
    selectedTypeInt: {
      type: Object,
    }
  },
  data: () => ({
    valid: true,
    group: null,
    groupRules: [(v) => !!v || 'Выберите группу'],
  }),
  computed: {
    intGroupsPlaceholder () {
      if (this.selectedTypeInt.value === 'vk') {
        return 'Выберите группу'
      }
      return 'Выберите профиль'
    }
  },
  methods: {
    ...mapActions({
      createIntegrationVK: 'integrations/createIntegrationVK',
      createIntegrationInst: 'integrations/createIntegrationInst',
    }),
    validate () {
      return !!this.$refs.form.validate();
    },
    create () {
      if (this.validate()) {
        if (this.selectedTypeInt.value === 'vk') {
          let payload = {
            group_name: this.group.name,
            group_id: this.group.id.toString(),
            session_id: this.sessionId,
          };

          this.createIntegrationVK(payload).then((response) => {
            eventBus.$emit('integrationVKCreate', response);
          });
        } else if (this.selectedTypeInt.value === 'instagram') {
          let payload = {
            page_name: this.group.name,
            page_id: this.group.id.toString(),
            session_id: this.sessionId,
          };

          this.createIntegrationInst(payload);
        }
      }
    },
    closeDialog () {
      this.$emit('close');
      this.group = null;
      this.$refs.form.resetValidation();
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
