<template>
  <v-card class="modal-create">
    <v-form ref="form" v-model="valid">
      <v-card-title class="d-flex justify-center align-center mb-5">
        <span class="text-h5 mr-2 text-center">
          Создание интеграции
        </span>
        <img width="31" :src="require(`@/assets/img/integrations/${selectedTypeInt.value}.svg`)" alt=""/>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')" />
        </div>
        <v-col
            cols="12"
        >
          <small>
            Где взять данные?
            <v-menu
                top
                offset-y
                open-on-hover
            >
              <template v-slot:activator="{ on, attrs }">
                <strong v-bind="attrs" v-on="on"><v-icon size="18" color="#289E80">mdi-information-outline</v-icon></strong>
              </template>
              <v-list-item class="user-color-status d-flex justify-space-between align-center pa-3">
                <p>
                  Для интеграции с почтовым клиентом, необходимо предоставить серверу
                  <br>
                  параметры, указанные ниже.
                  <br>
                  Их можно посмотреть в настройках почтового аккаунта.
                  <br>
                  Ссылки на инструкции у популярных почтовых сервисов:
                  <br>
                  Яндекс.Почта - <a href="https://yandex.ru/support/mail/mail-clients/others.html" target="_blank">Инструкция</a>
                  <br>
                  Gmail - <a href="https://developers.google.com/gmail/imap/imap-smtp?hl=ru" target="_blank">Инструкция</a>
                  <br>
                  Mail Ru - <a href="https://help.mail.ru/mail/mailer/popsmtp/" target="_blank">Инструкция</a>
                  <br>
                  Обращаем ваше внимание: "пароль внешнего приложения" это не пароль от почтового ящика.
                  <br>
                  Пароль внешнего приложения создается в настройках вашей почты. В инструкциях это указано.
                </p>
              </v-list-item>
            </v-menu>
          </small>
        </v-col>
        <v-col
            cols="12"
        >
          <v-text-field
              class="evi-text-field mb-4"
              color="#44D370"
              v-for="(value, key) in mail"
              v-model="mail[key]"
              :rules="fieldRules(key)"
              :key="key"
              :placeholder="placeholder(key)"
              :type="fieldType(key)"
              :append-icon="appendIconPassword(key)"
              @click:append="showOutPassword = !showOutPassword"
          >
            <template v-slot:label>
              {{fieldLabel(key)}}
              <span class="important">*</span>
            </template>
          </v-text-field>
        </v-col>
      </v-card-text>
      <v-card-actions class="modal-create__actions">
        <v-btn class="evi-button-green evi-button-green--fill" @click="create">
          Создать интеграцию
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "CreateIntegrationMail",
  props: {
    selectedTypeInt: {
      type: Object,
    }
  },
  data: () => ({
    valid: true,
    showOutPassword: false,
    rules: [(v) => !!v || 'Заполните поле'],
    mail: {
      name: '',
      imap_host: '',
      imap_port: '',
      smtp_host: '',
      smtp_port: '',
      out_password: '',
    },
  }),
  computed: {
    emailRules () {
      return [
        (v) => !!v || 'Введите почту',
        (v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Неверный формат эл. почты',
      ];
    },
  },
  methods: {
    ...mapActions({
      createIntegration: 'integrations/createMailIntegration',
    }),
    validate () {
      return !!this.$refs.form.validate();
    },
    create () {
      if (this.validate()) {
        let payload = {
          name: this.mail.name,
          imap_host: this.mail.imap_host,
          imap_port: this.mail.imap_port,
          imap_username: this.mail.name,
          imap_password: this.mail.out_password,
          smtp_host: this.mail.smtp_host,
          smtp_port: this.mail.smtp_port,
          smtp_username: this.mail.name,
          smtp_password: this.mail.out_password,
        };
        this.createIntegration(payload).then(() => this.closeDialog());
      }
    },
    fieldLabel (key) {
      if (key === 'name') {
        return 'Электронный адрес';
      } else if (key === 'imap_host') {
        return 'IMAP-сервер';
      } else if (key === 'smtp_host') {
        return 'SMTP-сервер';
      } else if (key === 'imap_port') {
        return 'IMAP Порт';
      } else if (key === 'smtp_port') {
        return 'SMTP Порт';
      } else if (key === 'out_password') {
        return 'Пароль внешнего приложения';
      }
      return key;
    },
    placeholder (key) {
      if (key === 'name') {
        return 'name@domain.ru';
      } else if (key === 'imap_host') {
        return 'imap.domain.ru';
      } else if (key === 'smtp_host') {
        return 'smtp.domain.ru';
      } else if (key === 'imap_port') {
        return '993';
      } else if (key === 'smtp_port') {
        return '465';
      } else if (key === 'out_password') {
        return 'Examplep@ssword';
      }
    },
    fieldType (key) {
      if (key === 'out_password') {
        return this.showOutPassword ? 'text' : 'password';
      }
      return 'text';
    },
    appendIconPassword (key) {
      if (key === 'out_password') {
        return this.showOutPassword ? 'mdi-eye' : 'mdi-eye-off'
      }
      return '';
    },
    fieldRules (key) {
      if (key === 'name') {
        return this.emailRules;
      }
      return this.rules;
    },
    closeDialog () {
      this.$emit('close');
      this.token = '';
      this.$refs.form.resetValidation();
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
