<template>
  <v-card class="modal-create">
    <v-form ref="form" v-model="valid">
      <v-card-title class="d-flex justify-center">
        <span class="text-h5 text-center mb-11">Настройка интеграции</span>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')" />
        </div>
        <v-col
            cols="12"
        >
          <v-autocomplete
              class="evi-autocomplete"
              color="#44D370"
              v-model="selectedUsers"
              :items="localUsers"
              item-text="name"
              item-value="id"
              placeholder="Выберите сотрудников"
              :rules="userRules"
              multiple
              :loading="userLoading"
              @focus="loadUsers"
              :search-input.sync="userSearch"
              @input.native="searchUser(userSearch)"
              cache-items
              clearable
              item-color="green"
          >
            <template v-slot:label>
              Выберите ответственных сотрудников
              <span class="important">*</span>
            </template>
            <template v-slot:append-item>
              <div v-intersect="userIntersect" />
            </template>
            <template v-slot:item="{item}">
              <v-avatar
                  color="#FFFFFF"
                  size="30"
                  class="user-page__avatar mr-3"
              >
                <img
                    :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                    alt=""
                >
              </v-avatar>
              <div class="v-list-item__title">{{item.name}}</div>
            </template>
            <template v-slot:append>
                <span class="evi-select__arrow">
                    <v-icon>mdi-chevron-down</v-icon>
                </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
            cols="12"
        >
          <v-text-field
              class="evi-text-field form-name mb-2"
              color="#44D370"
              v-model="formName"
          >
          </v-text-field>
          <v-text-field
              class="evi-text-field form-name"
              color="#44D370"
              v-model="email"
              label="Дублировать на почту"
              :rules="emailRules"
          >
          </v-text-field>
        </v-col>
        <v-col
            cols="12"
        >
          <v-text-field
              v-for="(i, index) in fields"
              :key="index"
              class="evi-text-field"
              color="#44D370"
              v-model="i.placeholder"
          >
            <template v-slot:label>
              Название поля
            </template>
            <template v-slot:append>
              <v-icon v-if="showTrash(i.name)" @click="removeField(index)" class="pointer  " color="#A7AFB8">mdi-trash-can-outline</v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col
            cols="12"
        >
          <div class="add-field pointer mb-2" @click="newField">
            <v-icon>mdi-plus</v-icon>
            Добавить поле
          </div>
        </v-col>
        <v-col
            cols="12"
        >
          <v-textarea
              class="evi-textarea"
              color="#44D370"
              v-model="message"
              no-resize
          >
            <template v-slot:label>
              Сообщение после заполнения формы
            </template>
          </v-textarea>
        </v-col>
      </v-card-text>
      <v-card-actions class="modal-create__actions">
        <v-btn class="evi-button-green evi-button-green--fill" @click="save">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import {mapActions} from "vuex";
  import {UserAutocompleteRequestsMixin} from "@/mixins/autocomplete/UserAutocompleteRequestsMixin";
  import {translit} from "../../tools/Helpers";

export default {
  name: "EditIntegrationForms",
  mixins: [UserAutocompleteRequestsMixin],
  props: {
    selectedInt: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    valid: true,
    selectedUsers: [],
    userRules: [(v) => !!v || 'Выберите сотрудников'],
    intUsers: [],
    formName: '',
    fields: [],
    message: '',
    email: '',
  }),
  computed: {
    localUsers () {
      let array = [...this.intUsers];
      this.users.forEach(i => {
        if (!this.intUsers.some(e => e.id === i.id)) {
          array.push(i);
        }
      });
      return array;
    },
    emailRules() {
      return [
        (v) => !!v || 'Введите почту',
        (v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Неверный формат эл. почты',
      ];
    },
  },
  methods: {
    ...mapActions({
      getIntegration: 'integrations/getIntegration',
      editIntegrationForms: 'integrations/editIntegrationFormsNoAlert',
      editIntegrationResponsibleUsers: 'integrations/editIntegrationResponsibleUsers',
    }),
    save () {
      this.fields.forEach(i => {
        if (i.name === 'new_field' || (
            i.name !== 'name' && i.name !== 'email' && i.name !== 'phone'
        )) {
          i.name = translit(i.placeholder)
        }
        i.label = i.placeholder;
      });

      let payload = {
        name: this.formName,
        message: this.message,
        email: this.email || null,
        fields: this.fields,
        id: this.selectedInt.id,
      };

      this.editIntegrationResponsibleUsers(
          {
            id: this.selectedInt.id,
            selectedUsers: this.selectedUsers,
          }
      );
      this.editIntegrationForms(payload).then(() => {
        this.$emit('update');
        this.closeDialog();
      });
    },
    closeDialog () {
      this.$emit('close');
      this.$refs.form.resetValidation();
    },
    initIntegration(val) {
      this.selectedUsers = [];
      this.getIntegration(val).then((res) => {
        res.users.forEach(i => this.selectedUsers.push(i.id));
        this.intUsers = res.users ? res.users : [];
        this.fields = res.form_params.fields ? res.form_params.fields : [];
        this.message = res.form_params.message ? res.form_params.message : '';
        this.formName = res.name ? res.name : '';
        this.email = res.form_params.email ? res.form_params.email : '';
      });
    },
    newField () {
      this.fields.push({
        placeholder: 'Новое поле',
        name: 'new_field',
        type: 'text',
      })
    },
    showTrash(v) {
      return v !== 'name' && v !== 'email' && v !== 'phone';
    },
    removeField(i) {
      this.fields.splice(i, 1);
    },
  },
  mounted () {
    this.initIntegration(this.selectedInt.id);
  },
  watch: {
    selectedInt (val) {
      this.initIntegration(val.id);
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
