<template>
  <div class="integrations">
    <div class="integrations__items">
      <div class="d-flex align-center justify-space-between ml-6">
        <div class="d-flex align-center" v-if="selectedTypeInt">
          <img class="mr-3" :src="require(`@/assets/img/integrations/${selectedTypeInt.value}.svg`)" alt=""/>
          <h3 class="mr-9">
            Интеграции {{ selectedTypeInt.name }}
            <v-menu
                top
                offset-y
                open-on-hover
                v-if="selectedTypeInt.information"
            >
              <template v-slot:activator="{ on, attrs }">
                <strong v-bind="attrs" v-on="on"><v-icon size="18" color="#289E80">mdi-information-outline</v-icon></strong>
              </template>
              <v-list-item class="user-color-status d-flex justify-space-between align-center pa-3">
                {{ selectedTypeInt.information }}
              </v-list-item>
            </v-menu>
          </h3>
        </div>
        <div class="d-flex align-center">
          <v-btn v-if="hasRight('integrations.manage') && canCreateInt" class="evi-button-green evi-button-green--fill mr-3" @click="authInt()">
            Добавить интеграцию
          </v-btn>
          <v-btn
              outlined
              class="evi-button-white mr-4"
              @click="$router.push({path: `/integrations`})"
          >
            <v-icon>mdi-chevron-left</v-icon>
            Назад
          </v-btn>
        </div>
      </div>
      <EsFiltration :showByOptions="showByOptions" :itemsLength="tableMeta.total" :showBy="24" @updatePages="getIntegrationsByTypePages">

      </EsFiltration>
      <div class="integrations__all" v-if="!loading">
        <div class="integrations__item" :style="{backgroundColor: selectedTypeInt ? selectedTypeInt.color : ''}" @click="openIntPage(i.id)" v-for="i in integrations" :key="i.id">
          <div class="d-flex justify-space-between">
            <img width="41" :src="require(`@/assets/img/integrations/types/${$route.params.id}.svg`)" alt=""/>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div class="integrations__dots" v-on="on" v-bind="attrs" v-if="hasRight('integrations.manage')">
                  <v-icon>mdi-dots-horizontal</v-icon>
                </div>
              </template>
              <v-list dense class="settings-menu">
                <v-list-item-group>
                  <v-list-item class="integrations-menu__item" @click="editIntOpenModal(i)">
                    <img width="20" class="mr-2" :src="require(`@/assets/img/integrations/settings.svg`)" alt=""/>
                    <v-list-item-title>Настроить</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="integrations-menu__item" @click="deleteIntOpenModal(i)">
                    <v-list-item-icon class="mr-2">
                      <v-icon color="#ED4245">mdi-trash-can-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Удалить</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </div>
          <div class="integrations__name">
            {{ i.name }}
          </div>
        </div>
      </div>
    </div>
    <v-dialog
        v-model="createVKIntModal"
        max-width="612px"
    >
      <CreateIntegration :selectedTypeInt="selectedTypeInt" :groups="groups" :sessionId="sessionId" @update="getIntegrations"
                           @close="createVKIntModal = false"/>
    </v-dialog>
    <v-dialog
        v-model="createTgIntModal"
        max-width="612px"
    >
      <CreateIntegrationTg :selectedTypeInt="selectedTypeInt" @update="getIntegrations" @close="createTgIntModal = false"/>
    </v-dialog>
    <v-dialog
        v-model="createFormsIntModal"
        max-width="612px"
    >
      <CreateIntegrationForms :selectedTypeInt="selectedTypeInt" @update="getIntegrations" @close="createFormsIntModal = false"/>
    </v-dialog>
    <v-dialog
        v-model="createSdekIntModal"
        max-width="612px"
    >
      <CreateIntegrationSdek :selectedTypeInt="selectedTypeInt" @update="getIntegrations" @close="createSdekIntModal = false"/>
    </v-dialog>
    <v-dialog
        v-model="createMailIntModal"
        max-width="612px"
    >
      <CreateIntegrationMail :selectedTypeInt="selectedTypeInt" @update="getIntegrations" @close="createMailIntModal = false"/>
    </v-dialog>
    <v-dialog
        v-model="deleteIntModal"
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Удаление интеграции
        </v-card-title>
        <v-card-text>Вы действительно хотите удалить интеграцию "{{ selectedIntegration.name }}"?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="deleteIntModal = false"
          >
            Отмена
          </v-btn>
          <v-btn
              color="red"
              text
              @click="deleteInt"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="editIntModal"
        max-width="612px"
    >
      <EditIntegration @close="editIntModal = false" :selectedInt="selectedIntegration"/>
    </v-dialog>
    <v-dialog
        v-model="editIntFormModal"
        max-width="612px"
    >
      <EditIntegrationForms @close="editIntFormModal = false" @update="getIntegrations" :selectedInt="selectedIntegration"/>
    </v-dialog>
  </div>
</template>

<script>
import EsFiltration from "../../components/сommon/EsTable/EsFiltration";
import {mapActions, mapState} from "vuex";
import {eventBus} from '../../main';
import CreateIntegration from "../../components/integrations/CreateIntegration";
import EditIntegration from "../../components/integrations/EditIntegration";
import {hasRight} from '@/utils/access/hasRight';
import {OpenPagesMixin} from "@/mixins/OpenPagesMixin";
import CreateIntegrationTg from "../../components/integrations/CreateIntegrationTg";
import CreateIntegrationForms from "../../components/integrations/CreateIntegrationForms";
import EditIntegrationForms from "../../components/integrations/EditIntegrationForms";
import CreateIntegrationSdek from "@/components/integrations/CreateIntegrationSdek";
import CreateIntegrationMail from "../../components/integrations/CreateIntegrationMail.vue";

var openedWindow;

export default {
  name: "Integrations",
  components: {
    CreateIntegrationMail,
    CreateIntegrationSdek,
    EditIntegrationForms,
    CreateIntegrationForms, CreateIntegrationTg, EditIntegration, CreateIntegration, EsFiltration},
  mixins: [OpenPagesMixin],
  data: () => ({
    showByOptions: [
      {
        text: '4',
        counter: 4,
      },
      {
        text: '8',
        counter: 8,
      },
      {
        text: '12',
        counter: 12,
      },
      {
        text: '24',
        counter: 24,
      },
      {
        text: '50',
        counter: 50,
      },
    ],
    createVKIntModal: false,
    createFormsIntModal: false,
    createSdekIntModal: false,
    createTgIntModal: false,
    createMailIntModal: false,
    deleteIntModal: false,
    editIntModal: false,
    editIntFormModal: false,
    groups: [],
    sessionId: '',
    secondOpenedWindow: null,
    selectedIntegration: {},
    typeFilter: '',
    selectedTypeInt: null,
    strWindowFeatures: "location=yes,height=570,width=720,scrollbars=yes,status=yes",
  }),
  computed: {
    ...mapState("common", ["loading", "tableMeta"]),
    ...mapState("user", ["profile", "webSocketData"]),
    ...mapState("integrations", ["integrations", "integrationTypes", "extremists"]),
    canCreateInt () {
      return this.$route.params.id !== 'cdek' || !this.integrations.length;
    }
  },
  methods: {
    ...mapActions({
      getIntegrationsPages: 'integrations/getIntegrationsPages',
      getFormsPages: 'integrations/getFormsPages',
      sessionIntegrationVK: 'integrations/sessionIntegrationVK',
      sessionIntegrationInst: 'integrations/sessionIntegrationInst',
      createIntegrationTg: 'integrations/createIntegrationTg',
      createMailIntegration: 'integrations/createMailIntegration',
      deleteIntegration: 'integrations/deleteIntegration',
      deleteIntegrationForm: 'integrations/deleteIntegrationForm',
    }),
    hasRight: hasRight,
    isExtremist (v) {
      return this.extremists.some(i => i === v);
    },
    getIntegrations () {
      this.getIntegrationsPages({
        per_page: 8,
        page: 1,
        'filter[type]': this.$route.params.id,
      })
    },
    getIntegrationsByTypePages (query) {
      let queryWithFilterType = query;
      queryWithFilterType['filter[type]'] = this.$route.params.id;
      this.getIntegrationsPages(queryWithFilterType);
    },
    openVKAuth() {
      this.sessionIntegrationVK().then((res) => {
        openedWindow = window.open(res, "_blank", this.strWindowFeatures);
      });
    },
    openInstAuth() {
      this.sessionIntegrationInst().then((res) => {
        openedWindow = window.open(res, "_blank", this.strWindowFeatures);
      });
    },
    openTgAuth () {
      this.createTgIntModal = true;
    },
    openFormsAuth () {
      this.createFormsIntModal = true;
    },
    openSdekAuth () {
      this.createSdekIntModal = true;
    },
    openMailAuth () {
      this.createMailIntModal = true;
    },
    authInt() {
      if (this.$route.params.id === 'vk') {
        this.openVKAuth();
      } else if (this.$route.params.id === 'instagram') {
        this.openInstAuth();
      } else if (this.$route.params.id === 'telegram') {
        this.openTgAuth();
      } else if (this.$route.params.id === 'form') {
        this.openFormsAuth();
      } else if (this.$route.params.id === 'cdek') {
        this.openSdekAuth();
      } else if (this.$route.params.id === 'mail') {
        this.openMailAuth();
      }
      this.selectedTypeInt = this.integrationTypes.find(i => i.value === this.$route.params.id);
    },
    intConnect () {
      this.$nextTick(() => {
        eventBus.$on('integrationVKCreate', data => {
          this.secondOpenedWindow = window.open(data, "_blank", this.strWindowFeatures);
        })
        this.$echo.private(`integrations.${this.profile.id}`)
            .listen('VKUserGroupsLoaded', payload => {
              this.groups = payload.groups;
              this.sessionId = payload.session_id;
              this.createVKIntModal = true;
              openedWindow.close();
            })
            .listen('InstagramPagesLoaded', payload => {
              // console.warn(payload);
              this.groups = payload.pages;
              this.sessionId = payload.session_id;
              this.createVKIntModal = true;
              openedWindow.close();
            })
            .listen('IntegrationCreated', payload => {
              if (payload) {
                if (this.secondOpenedWindow) this.secondOpenedWindow.close();
                this.createVKIntModal = false;
                this.getIntegrations();
              }
            });
      });
    },
    deleteIntOpenModal (i) {
      this.selectedIntegration = i;
      this.deleteIntModal = true;
    },
    deleteInt () {
      this.deleteIntegration(this.selectedIntegration).then(() => {
        this.deleteIntModal = false;
        this.getIntegrations();
      });
    },
    editIntOpenModal (i) {
      this.selectedIntegration = i;
      if (this.$route.params.id === 'form') {
        this.editIntFormModal = true;
      } else {
        this.editIntModal = true;
      }
    }
  },
  mounted() {
    this.intConnect();
    this.selectedTypeInt = this.integrationTypes.find(i => i.value === this.$route.params.id);
  },
  watch: {
    'webSocketData.key'(val) {
      if (val) {
        this.intConnect();
      }
    },
  }
}
</script>

<style lang="scss">

</style>
